import React, { useContext } from 'react';
import {  BodyComponent } from '../../../util/pageGraph';
import { DynamicSection } from '../layout';
import Table from './Table';
import Markdown from './Markdown';
import MediaComponent from './Media';
import SubjectContact from '../subjectContact';
import Highlights from '../highlights';
import { BlockType } from '../../../api/sitePages';
import Button from '../button';

type BodyProps = {
  body: BodyComponent[],
  title?: string;
}

const Body = ({
  body, title
}: BodyProps) => {

  return (
    <>
      {body.map((section, i) => {
        if (section.type === 'markdown') {
          return (
            <DynamicSection>
              <Markdown key={i} data={section.data} />
            </DynamicSection>
          );
        }
        if (section.type === 'table') {
          return (
            <DynamicSection>
              <Table rows={section.data.rows} mobileRows={section.data.mobileRows} style={section.data.style} />
            </DynamicSection>
          );
        }
        if (section.type === 'media') {
          return (
            <DynamicSection>
              <MediaComponent {...section} />
            </DynamicSection>
          );
        }
        if (section.type === 'block') {
          if (section.data === BlockType.contact) {
            return <SubjectContact subject={title || ''} />
          }
          if (section.data === BlockType.highlights) {
            return <Highlights />
          }
        }
        if (section.type === 'button') {
          let buttonProps = {} as any;
          if (!section.data.url.startsWith('/')) {
            // external site
            buttonProps = {
              onClick: () => {
                window.open(section.data.url, '_blank')?.focus();
              }
            }
          } else {
            buttonProps = {
              to: section.data.url,
              type: 'link'
            }
          }
          return <Button
            {...buttonProps}
          >
            {section.data.copy}
          </Button>
        }
        return null
      })}
    </>
  );
}

export default Body;