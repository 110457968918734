import axios from "axios";
import dependencyGraph from "dependency-graph";
import { baseUrl } from "./index";
import PageGraph from "../util/pageGraph";

export const apiUrl = `${baseUrl}/api`;

const { DepGraph } = dependencyGraph;

export const sortDescDate = "sort=createdAt:desc";
export const sortWrittenDate = "sort=dateWritten:desc";

export type HighlightAttribute = {
  title: string;
  subTitle: string | undefined;
  articleContent: string;
  author: string;
  createdAt: string;
  dateWritten: string;
  articlePreview: string;
  urlTitle: string;
};

export type PageAttribute = {
  Title: string;
  title: string;
  body: BodySection[];
  metaTitle: string;
  metaDescription: string;
  metaKeywords: string;
  url: string;
  parent: {
    data: ContentResponse<PageAttribute> | null;
  };
  createdAt: Date;
  sort: number;
  menuName?: string;
};

export type CarouselAttribute = {
  column_one: BodySection[];
  column_two: BodySection[];
  layout: "wide-column" | "half-column";
  sort: number;
  background_image_desktop: MediaApi;
  background_image_mobile: MediaApi;
  text_color: "dark" | "light";
};

export type ContentResponse<T> = {
  id: number;
  attributes: T;
};

export const fetchApi = async <T>(url: string) => {
  const { data: body } = await axios.get<ApiResponse<T>>(url, {
    headers: {
      Accept: "application/json",
    },
  });
  const { data, meta } = body;
  return { data, meta };
};

type Meta = {
  pagination: {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
  };
};
export type Page = {};

export type ApiResponse<T> = {
  meta: Meta;
  data: T;
};

export type ApiPageData = {
  id: number;
  title: string;
  body: BodySection[];
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string;
  url: string;
  sort: number;
  menuName?: string;
};

export type ProductTableCell = {
  text?: string;
  header?: boolean;
  colSpan?: number;
  rowSpan?: number;
  bgColor?: string;
  color?: string;
  style?: any;
  markdownStyle?: any;
};

type ProductTableApi = {
  data: {
    attributes: {
      name: string;
      data: {
        style?: {
          border: 0;
        };
        mobileRows?: ProductTableCell[][];
        rows: ProductTableCell[][];
      };
      updatedAt: string;
    };
  };
};

export type MediaFormatData = {
  url: string;
  height: number;
  width: number;
};

export type MediaFormat = {
  large: MediaFormatData;
  medium: MediaFormatData;
  small: MediaFormatData;
  thumbnail: MediaFormatData;
};

type MediaAttributes = {
  name: string;
  url: string;
  caption?: string;
  ext: string;
  formats: MediaFormat;
};

export type MediaApi = {
  data: ContentResponse<MediaAttributes> | ContentResponse<MediaAttributes>[];
};

export enum BlockType {
  contact = "contact about {title}",
  highlights = "browse all highlights",
}
// if a body section has all 3, the order of appearance
// will always be
// 1. rich text
// 2. product table
// 3. media
// 4. block "enum"
export type BodySection = {
  richtext: string;
  product_table: ProductTableApi;
  media: MediaApi;
  block: BlockType;
  button: BodyButton;
};

export type BodyButton = {
  copy: string;
  url: string;
};

function createPageGraph(pages: ContentResponse<PageAttribute>[]): PageGraph {
  const graph = new DepGraph<ApiPageData>();
  pages.forEach((page) => {
    const { id, attributes: pageAttributes } = page;
    const {
      parent,
      title,
      body,
      metaTitle,
      metaDescription,
      metaKeywords,
      url,
      sort,
      menuName,
    } = pageAttributes;
    const pageData = {
      id,
      title,
      body,
      metaTitle,
      metaDescription,
      metaKeywords,
      url,
      sort,
      menuName,
    };
    const nodeName = title;
    if (graph.hasNode(nodeName)) {
      graph.setNodeData(nodeName, pageData);
    } else {
      graph.addNode(nodeName, pageData);
    }
    if (parent.data) {
      const parentNodeName = parent.data.attributes.title;
      if (!graph.hasNode(parentNodeName)) {
        graph.addNode(parentNodeName);
      }
      graph.addDependency(parentNodeName, nodeName);
    }
  });

  return new PageGraph(graph);
}

export const buildUrlFromNode = (
  pageName: string,
  pageGraph: dependencyGraph.DepGraph<ApiPageData>,
  url = ""
): string => {
  const [parentPage] = pageGraph.directDependantsOf(pageName);
  if (!parentPage) {
    return url;
  }
  const { url: parentUrl } = pageGraph.getNodeData(parentPage);
  return buildUrlFromNode(parentPage, pageGraph, `${parentUrl}/${url}`);
};

// search
// body:
// https://api.novitaschem.com/api/pages?populate[parent]=*&populate[body][populate]=*&filters[body][richtext][$contains]=Extreme
// title:
// https://api.novitaschem.com/api/pages?populate[parent]=*&filters[title][$contains]=Additives
export async function getPages(): Promise<{
  pageGraph: PageGraph;
  highlights: ContentResponse<HighlightAttribute>[];
  carousel: ContentResponse<CarouselAttribute>[];
}> {
  try {
    const { data: pageData } = await fetchApi<ContentResponse<PageAttribute>[]>(
      `${apiUrl}/pages?populate[parent]=*&populate[body][populate]=*`
    );
    const { data: highlightData } = await fetchApi<
      ContentResponse<HighlightAttribute>[]
    >(`${apiUrl}/blogs?pagination[pageSize]=50&${sortWrittenDate}`);
    const { data: carouselData } = await fetchApi<
      ContentResponse<CarouselAttribute>[]
    >(
      [
        `${apiUrl}/carousels?populate[column_one][populate]=*`,
        "populate[column_two][populate]=*",
        "populate[background_image_desktop][populate]=*",
        "populate[background_image_mobile][populate]=*",
      ].join("&")
    );
    console.log(carouselData);
    return {
      pageGraph: createPageGraph(pageData),
      highlights: highlightData,
      carousel: carouselData,
    };
  } catch (error) {
    throw errorHandler(error);
  }
}

export const errorHandler = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    console.error("error message: ", error.message);
    return error.message;
  }
  console.error("unexpected error: ", error);
  return error;
};
