import { createContext } from "react";
import {
  ContentResponse,
  HighlightAttribute,
  CarouselAttribute,
} from "./api/sitePages";
import PageGraph from "./util/pageGraph";

type State = {
  pageGraph: PageGraph;
  highlights: ContentResponse<HighlightAttribute>[];
  carousel: ContentResponse<CarouselAttribute>[];
};
const PageContext = createContext<State>({
  pageGraph: {} as any,
  highlights: [],
  carousel: [],
});
export default PageContext;
