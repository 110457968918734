import {
  ApiPageData,
  BlockType,
  BodySection,
  BodyButton,
  ProductTableCell,
  MediaApi,
  MediaFormat,
} from "../api/sitePages";
import dependencyGraph from "dependency-graph";
import { baseUrl } from "../api/index";

type ProductTable = {
  type: "table";
  name: string;
  updatedAt: string;
  data: {
    rows: ProductTableCell[][];
    mobileRows?: ProductTableCell[][];
    style?: any;
  };
};

type RichText = {
  type: "markdown";
  data: string;
};

export type Media = {
  type: "media";
  name: string;
  url: string;
  caption: string;
  previewUrl?: string;
  ext: ".pdf" | ".jpeg" | ".png";
  formats: MediaFormat;
};

type BlockComponent = {
  type: "block";
  data: BlockType;
};

type ButtonComponent = {
  type: "button";
  data: BodyButton;
};

export type BodyComponent =
  | ProductTable
  | RichText
  | Media
  | BlockComponent
  | ButtonComponent;

export type PageData = {
  breadcrumb: { name: string; url: string }[];
  url: string; // full url
  parent: string | null;
  children: string[];
  body: BodyComponent[];
  title: string;
  sort: number;
  menuName?: string;
  meta: {
    title: string;
    description: string;
    keywords: string;
  };
};

export const parseMedia = (media: MediaApi) => {
  const main = Array.isArray(media.data)
    ? media.data.find((d) => d.attributes.name != "preview")
    : media.data;

  const mediaData = {
    type: "media",
    name: main?.attributes.name,
    ext: main?.attributes.ext,
    url: main?.attributes.url,
    caption: main?.attributes.caption,
    formats: main?.attributes.formats,
  } as Media;

  const preview =
    Array.isArray(media.data) &&
    media.data.find((d) => d.attributes.name === "preview");
  if (preview) {
    // this is a preview, preview should have "preview" in the file name
    mediaData.previewUrl =
      preview.attributes.formats?.thumbnail?.url ?? preview.attributes.url;
  }
  return mediaData;
};

export const parseBodySections = (body: BodySection[]): BodyComponent[] => {
  return body.reduce(
    (
      sections: BodyComponent[],
      { richtext, media, product_table, block, button }
    ) => {
      if (richtext) {
        sections.push({ type: "markdown", data: richtext });
      }
      if (product_table.data) {
        const { attributes } = product_table.data;
        sections.push({
          type: "table",
          name: attributes.name,
          updatedAt: attributes.updatedAt,
          data: attributes.data,
        });
      }
      if (media.data) {
        sections.push(parseMedia(media));
      }
      if (block) {
        sections.push({ type: "block", data: block });
      }
      if (button) {
        sections.push({ type: "button", data: button });
      }
      return sections;
    },
    []
  );
};

export type NavItem = {
  url: string;
  title: string;
  isParent: boolean;
  children: NavItem[];
};

export default class PageGraph {
  _graph: dependencyGraph.DepGraph<ApiPageData>;
  _map: { [key: string]: PageData };
  pages: PageData[];
  nav: NavItem[];

  constructor(graph: dependencyGraph.DepGraph<ApiPageData>) {
    this._graph = graph;
    this._map = {};
    this.pages = this._getPages();
    this.nav = this._getNav();
  }

  _getPages() {
    const topLevel = this._graph.entryNodes();
    // go through every entry and build
    return topLevel.reduce((pages: PageData[], node) => {
      return [...pages, ...this._buildPages(node, null)];
    }, []);
  }

  _getNav(): NavItem[] {
    const topLevel = this._graph.entryNodes();
    // go through every entry and build
    return this._buildNav(topLevel);
  }

  _buildNav(nodes: string[]): NavItem[] {
    if (nodes.length === 0) {
      return [];
    }
    return nodes
      .sort((a, b) => {
        const aData = this._map[a];
        const bData = this._map[b];
        return aData.sort - bData.sort;
      })
      .map((node: string) => {
        const pageData = this._map[node];
        console.log(pageData.menuName);
        return {
          isParent: !pageData.parent,
          title: pageData.menuName ?? pageData.title,
          url: pageData.url.trim(),
          children: this._buildNav(pageData.children),
        };
      });
  }

  _buildPages(node: string, parent: PageData | null): PageData[] {
    // node data
    this._setPageData(node, parent);
    const pageData = this._map[node];
    if (pageData.children.length === 0) {
      return [pageData];
    }
    const pages = [pageData];
    pageData.children.forEach((child) => {
      pages.push(...this._buildPages(child, pageData));
    });
    return pages;
  }

  _setPageData(node: string, parent: PageData | null) {
    // node data
    const {
      title,
      body,
      metaTitle,
      metaDescription,
      metaKeywords,
      url,
      menuName,
      sort = -1,
    } = this._graph.getNodeData(node);
    const pageUrl = `${parent ? parent.url : ""}/${url}`;
    const breadcrumb = { name: title, url: pageUrl };
    this._map[node] = {
      title,
      body: parseBodySections(body),
      children: this.getDirectChildren(node),
      parent: parent ? parent.title : null,
      sort,
      menuName,
      meta: {
        title: metaTitle || "",
        description: metaDescription || "",
        keywords: metaKeywords || "",
      },
      url: pageUrl,
      breadcrumb: [...(parent ? parent.breadcrumb : []), breadcrumb],
    };
  }

  getDirectChildren(node: string) {
    return this._graph.directDependenciesOf(node);
  }

  get(node: string) {
    return this._map[node];
  }
}
