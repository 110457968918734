import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { H1, H2, H4, H6 } from "../../copy";
import { Hero, Inner, Page } from "../../layout";
import Searchbar from "./searchbar";
import { scrollToTop } from "../../../../util/scrolltotop";
import { search, SearchProps } from "../../../../api/search";


const SearchResult = ({ url, createdAt, title, summary}: SearchProps) => {
  return (
    <Link
      onClick={scrollToTop}
      className="col-span-12 py-6 px-2 hover:bg-white-400 "
      to={url}
    >
      {!!createdAt && <H6>Date: {createdAt}</H6>}
      <H4>{title}</H4>
      <p>{summary}</p>
    </Link>
  );
}

const SearchPage: React.FC<{}> = () => {
  const [pages, setPages] = useState<SearchProps[]>([]);
  const [highlights, setHighlights] = useState<SearchProps[]>([]);
  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    search(id).then(r => {
      // display title vs rich text
      setPages(r.pages);
      setHighlights(r.highlights);
    });
  }, [id]);

  return (
    <Page>
      <Hero className=" relative min-h-[80vh] grow whitespace-normal">
        <Inner className="mb-10 grid grid-cols-12">
          <H1 className="col-span-12 mb-14 max-w-[800px]">Search: {id}</H1>
          <Searchbar open={true} initialValue={id} size={'large'}/>

          {highlights.length !== 0 && (
            <>
              <Link className="col-span-12 mt-8" to={"/products"}>
                <H2 className="underline">Highlight Results:</H2>
              </Link>
              <div className="col-span-12 grid grid-cols-12 divide-y ">
                {highlights &&
                  highlights.map((highlight, index) => (
                    <SearchResult key={index} {...highlight} />
                  ))}
              </div>
            </>
          )}

          {pages.length !== 0 && (
            <>
              <Link className="col-span-12 mt-8" to={"/products"}>
                <H2 className="underline">Product Results:</H2>
              </Link>
              <div className="col-span-12 grid grid-cols-12 gap-2">
                {pages &&
                  pages.map((page, index) => (
                    <SearchResult key={index} {...page} />
                  ))}
              </div>
            </>
          )}


          {pages.length === 0 && highlights.length === 0 && (
            <H6 className="col-span-12">No results matching '{id}'</H6>
            )}
        </Inner>
      </Hero>
    </Page>
  );
};

export default SearchPage;
