import React from 'react';
import { ClipLoader } from 'react-spinners';
import { Page, Section } from './layout';

const Loading = () => (
  <div>
    <ClipLoader />
  </div>
);

export const PageLoading = () => {
  return (
    <div className="min-h-screen flex h-fit max-w-[100vw] flex-col overflow-x-hidden">
      <Section>
          <div className="w-[35px] py-5 px-5">
            <ClipLoader size={50} />
          </div>
      </Section>
    </div>
  )
}

export default Loading;
