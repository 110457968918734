import { useContext } from "react";
import PageContext from "../../state";
import { classFilter } from "../../util/dom";
import Footer from "./footer";
import Header from "./header";


// header height 64px
// footer height 100px rounding
const Body: React.FC<LayoutProps> = ({ children }) => {
  return <div className={`min-h-[calc(100vh-64px-200px)]`}>
    {children}
  </div>
}

type LayoutProps = React.HTMLAttributes<HTMLDivElement>;

const Page: React.FC<LayoutProps> = (p) => {
  const { className, children, ...props } = p;
  const { pageGraph } = useContext(PageContext)

  return (
    <div
      {...props}
      className={classFilter(
        "min-h-screen flex h-fit max-w-[100vw] flex-col overflow-x-hidden",
        className ?? ""
      )}
    >
      <Header />
      <Body>
        {children}
      </Body>
      <Footer />
    </div>
  );
};

const Section: React.FC<LayoutProps> = (p) => {
  const { className, ...props } = p;

  return (
    <div
      {...props}
      className={classFilter(
        "flex flex-col items-center justify-between",
        className ?? ""
      )}
    />
  );
};

export const DynamicSection: React.FC<LayoutProps> = (p) => {
  const { className, ...props } = p;

  return (
    <div
      {...props}
      className={classFilter(
        "flex flex-col justify-between py-4",
        className ?? ""
      )}
    />
  );
};

const Inner: React.FC<LayoutProps> = (p) => {
  const { className, ...props } = p;

  return (
    <div
      {...props}
      className={classFilter(
        "w-full max-w-[1200px] py-10 px-4",
        className ?? ""
      )}
    />
  );
};

const Hero: React.FC<LayoutProps> = (p) => {
  const { className, ...props } = p;

  return (
    <Section {...props} className={classFilter("mt-28", className ?? "")} />
  );
};

export { Page, Section, Inner, Hero };
