import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/home";
import Highlights from "./pages/highlights";
import Contact from "./pages/contact";
import NotFound from "./pages/not-found";
import HighlightPage from "./pages/highlight";
import { useContext, useEffect, useState } from "react";
import SearchPage from "./ui/components/dynamic/search";
import ContentPage from "./ui/components/dynamic/ContentPage";
import PageContext from "./state";

const AppRouter = () => {
  const { pageGraph } = useContext(PageContext);
  const { pages } = pageGraph;
  console.log({ pages })
  return (
    <Router>
      <Routes>
        {/* main routes  */}
        <Route path="/" element={<Home />} />

        {/* page routes  */}
        {pages.map((page, i) => {
          const { url } = page;
          return <Route
            path={url}
            key={i}
            element={<ContentPage
              pageData={page}
             />}
          />
        })}

        {/* highlights */}
        <Route path="/company/highlights" element={<Highlights />} />
        <Route path="/company/highlights/:id/:urlTitle" element={<HighlightPage />} />

        <Route path="/search/:id" element={<SearchPage />}/>
        <Route path="/search" element={<SearchPage />}/>
        {/* <Route path="*" element={
          <PageLoading />
        } /> */}
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
