import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./index.css";

const RedirectToProd = () => {
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    if (seconds === 0) {
      window.location.replace("https://www.novitaschem.com");
    } else {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    }
  });
  return (
    <div className="py-5 px-5">
      You will be redirected to www.novitaschem.com in {seconds}{" "}
    </div>
  );
};

const redirectToProd =
  window.location.origin.includes("staging.novitaschem.com") &&
  !localStorage.getItem("alpha");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {redirectToProd ? <RedirectToProd /> : <App />}
  </React.StrictMode>
);
