import { dividerClasses } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Button, { Variant } from "../../button";

type Props = {
  open: boolean;
  initialValue?: string;
  size?: string;
};

const Searchbar = ({ open, initialValue, size = 'small'}: Props) => {
  const [value, updateValue] = useState(initialValue ?? '');
  const navigate = useNavigate();

  const updateSearch = (e: any) => {
    updateValue(e.target.value);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    navigate(`/search/${value.toLowerCase()}`)
  }
  const width = size === 'small'
    ? 'max-w-[150px]'
    : size === 'large'
    ? 'min-w-[250px]'
    : 'w-full';

  return (
     <div className="relative container mx-auto flex flex-wrap items-center justify-between bg-transparent border-1">
        <form onSubmit={handleSearch} className="flex">
          <input
            type="text"
            placeholder="Search..."
            className={`outline-0 rounded-sm pr-8 placeholder-opacity-50 py-1 px-2 ${width}
              border-b-2 border-gray-500 border-opacity-25 focus:border-opacity-75 bg-transparent
              focus:bg-white-400 hover:bg-white-400 focus:bg-opacity-100 hover:bg-opacity-100 placeholder-gray-500`}
            onInput={updateSearch}
            value={value}
          />

          <Button
            className="-ml-8 h-[34px] bottom-0 px-2 bg-transparent text-gray-900 shadow-none"
            type="submit"
            variant={Variant.text}
            disabled={value.length === 0}
          >
            <FaSearch color="black" className="h-[15px] w-[15px]" />
          </Button>
        </form>
    </div>
  );
};

export default Searchbar;
