import Carousel from "react-multi-carousel";
import Image from "../image";
import "react-multi-carousel/lib/styles.css";
import "./styles.css";
import PageContext from "../../../state";
import { useContext } from "react";
import { ContentResponse, CarouselAttribute } from "../../../api/sitePages";
import { BodyComponent, Media, parseBodySections, parseMedia } from "../../../util/pageGraph";
import { useMediaQuery } from "@mui/material";
import Body from '../dynamic/Body';
import styled from "@emotion/styled";
import theme from "../../styles/theme";

type CarouselCardProps = {
  layout: string;
  columns: BodyComponent[][];
  backgroundImage: Media;
  textColor: string;
};

const CarouselCard: React.FC<CarouselCardProps> = ({
  backgroundImage: backgroundImageMedia,
  layout,
  columns,
  textColor
}) => {
  const innerWidth = window.innerWidth;
  const backgroundImageUrl = (
    Object.values(backgroundImageMedia.formats || {})
      .find(d => d.width > innerWidth)
      ?? {}
    ).url ?? backgroundImageMedia.url;
  const backgroundImage = `url("${backgroundImageUrl}")`;

  // debugger;
  return (
    <CarouselTile
      className="carousel-tile"
      style={{
        backgroundImage,
        backgroundSize: 'cover'
      }}
    >
      <CarouselTileInner>
        <CarouselColumn 
          theme={textColor === 'dark' ? 'dark' : 'light'}
          style={{
            width: `${layout === 'half-column' ? 1200 / 2 : 1200}px`
          }}>
          <Body body={columns[0]} />
        </CarouselColumn>
      </CarouselTileInner>
    </CarouselTile>
  );
}


const createCarouselCards = (
  carousel: ContentResponse<CarouselAttribute>[],
  isMobile: boolean
): CarouselCardProps[] => {
  return carousel
    .sort((a, b) => a.attributes.sort - b.attributes.sort)
    .map(({ attributes }) => {
      const { column_one, column_two, layout, text_color, background_image_desktop, background_image_mobile } = attributes;
      const columns = [];
      if (column_one.length > 0) {
        columns.push(parseBodySections(column_one))
      }
      if (column_two.length > 0) {
        columns.push(parseBodySections(column_two))
      }
      return {
        textColor: text_color,
        layout,
        backgroundImage: isMobile ? parseMedia(background_image_mobile) : parseMedia(background_image_desktop),
        columns
      };
    });
}

const ResponsiveCarousel = () => {
  const { carousel } = useContext(PageContext);
  const isSmallScreen = useMediaQuery("(max-width: 550px)");
  const cards = createCarouselCards(carousel, isSmallScreen);

  console.log(cards);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <Carousel
      containerClass="carousel"
      responsive={responsive}
      swipeable={true}
      draggable={false}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={15000}
      transitionDuration={2000}
      customTransition={'transform 2000ms ease-in-out'}
      // removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
    >
      {cards.map(card => {
        return <CarouselCard
          {...card}
        />
      })}
      {/* <CarouselCard
        to={"/applications"}
        alt={"Image of windmill, Novitas makes lubricants for greener tech"}
        fileName={"carousel-windmill.jpg"}
        title="Experience Innovation At It's Best With Novitas"
        subtitle="Services & Applications: From Custom Formulations to Innovative Applications, Our Comprehensive Range of Chemical Services Can Help Your Business Meet Its Goals"
        webPage={false}
      />

       */}
      {/* <CarouselCard
        to={"/uploads/certificate.pdf"}
        alt={"Novitas ISO 9001:2015 Certification"}
        fileName={"certificate.jpg"}
        title="ISO 9001:2015 Certification"
        subtitle="We're happy to announce that Novitas Chem Solutions is now ISO 9001:2015 certified."
        webPage={false}
      /> */}
    </Carousel>
  );
};
export default ResponsiveCarousel;

const CarouselTile = styled('div')`
  # padding-left: ${theme.spacing.standard * 2}px;
  justify-content: center;
  align-items: center;
  display: flex;

  &:before {
  content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(to bottom right,#004f7e,#feffe6);
      opacity: .4; 
  }
`;

const CarouselTileInner = styled('div')`
  max-width: ${theme.contentWidth}px;
  width: 80vw;
  display: flex;
  justify-content: start;
`;

type CarouselColumnStyleProps = {
  theme: 'dark' | 'light'
}

const CarouselColumn = styled('div')`${
  (props: CarouselColumnStyleProps) => {
    let textTheme = `
      color: ${theme.colors.DarkBlue};
      text-shadow: .5px .5px 20px rgba(255, 255, 255, 0.55)
    `;
    if (props.theme === 'light') {
      textTheme = `
        color: ${theme.colors.White};
        text-shadow: .5px .5px 20px rgba(0, 0, 0, 0.75)
      `;
    }
    return `
      z-index: 2;
      h1, p {
        ${textTheme}
      }
    `;
  }
}`;