import { ReactNode } from "react";
import { Link, LinkProps, useNavigate } from "react-router-dom";
import { classFilter } from "../../util/dom";
import { scrollToTop } from "../../util/scrolltotop";

export enum Variant {
  filled = "filled",
  outlined = "outlined",
  text = "text",
}

type BaseProps = {
  disabled?: boolean;
  variant?: Variant;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};

type ActionButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  BaseProps & {
    type: "button" | "submit" | "reset";
  };

type LinkButtonProps = LinkProps &
  BaseProps & { type: "link"; contactSubjectMessage?: string };

type ButtonProps = ActionButtonProps | LinkButtonProps;

const BASE =
  "cursor-pointer py-2 px-8 rounded-full flex flex-row justify-flex-start gap-5 w-fit items-center shadow-md hover:cursor-pointer";

const variantClass = (
  variant: Variant | undefined = Variant.filled
): string => {
  switch (variant) {
    case Variant.filled:
      return BASE + " bg-blue-500 hover:bg-blue-400 text-blue-contrast";
    case Variant.outlined:
      return BASE + " border border-white border-[1px] border-solid";
    case Variant.text:
      return BASE;
  }
};

const ActionButton: React.FC<ActionButtonProps> = (p) => {
  const { variant, startIcon, endIcon, children, className, ...props } = p;

  return (
    <button
      {...props}
      className={classFilter(variantClass(variant), className ?? "")}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </button>
  );
};

const LinkButton: React.FC<LinkButtonProps> = (p) => {
  const {
    variant,
    startIcon,
    endIcon,
    children,
    className,
    contactSubjectMessage,
    disabled,
    ...props
  } = p;
  const navigate = useNavigate();

  const handleClickWithContactUsProp = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    scrollToTop(p)
    e.preventDefault();
    navigate(props.to as string, { state: { contactSubjectMessage } });
  };

  const style: any = {};
  if (disabled) {
    style.pointerEvents = "none";
  }
  return (
    <Link
      {...props}
      onClick={handleClickWithContactUsProp}
      className={classFilter(
        variantClass(variant),
        "no-underline",
        className ?? ""
      )}
      style={style}
    >
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </Link>
  );
};

const Button: React.FC<ButtonProps> = (props) => {
  switch (props.type) {
    case "link":
      return <LinkButton {...props} />;
    default:
      return <ActionButton {...props} />;
  }
};

Button.defaultProps = {
  type: "button",
};

export default Button;
