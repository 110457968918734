import { useContext, useEffect, useState } from "react";
import { FaEnvelope, FaLongArrowAltLeft } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Button from "../ui/components/button";
import { H2, H4 } from "../ui/components/copy";
import { Hero, Inner, Page } from "../ui/components/layout";
import PageContext from "../state";
import Markdown from "../ui/components/dynamic/Markdown";
import { format } from 'date-fns';
import { ContentResponse, HighlightAttribute } from "../api/sitePages";

const HighlightPage = () => {
  const { highlights } = useContext(PageContext);
  const { id } = useParams();

  const [highlight, setHighlight] = useState<ContentResponse<HighlightAttribute>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const dateParser = (articleDate: string) => {
    const [year, month, day] = articleDate.split("-");
    const result = `${month}/${day}/${year}`;
    return result;
  };

  useEffect(() => {
    if (!id) return;
    const highlight = highlights.find((h) => h.id === parseInt(id));
    setHighlight(highlight);
    setIsLoading(false);
  }, [id, highlights]);

  return (
    <Page>
      <Hero className="grow">
        {!isLoading && (
          <Inner>
            {highlight && (
              <>
                <H2 className="... mb-10 max-w-[1000px]  ">
                  {highlight.attributes.title}
                </H2>

                {highlight.attributes.author && (
                  <div className="flex">
                    <H4 className=" font-semibold">Author: </H4>
                    <H4 className="ml-2 font-normal ">
                      {highlight.attributes.author}
                    </H4>
                  </div>
                )}

                {highlight.attributes.dateWritten && (
                  <div className="mb-10 flex">
                    <H4 className="font-semibold">Written:</H4>
                    <H4 className="ml-2 font-normal">
                      {format(new Date(highlight.attributes.dateWritten), 'MMM dd, yyyy')}
                    </H4>
                  </div>
                )}

                {highlight.attributes.articleContent && (
                  <Markdown data={highlight.attributes.articleContent} />
                )}
              </>
            )}
            <div className="mt-14 mb-6 flex flex-col-reverse items-center gap-5 sm:mb-14 sm:flex-row">
              <Button
                type="link"
                to="/company/highlights"
                startIcon={<FaLongArrowAltLeft className="text-white-500 " />}
                className="w-5/6 place-content-center sm:w-fit"
              >
                Back to highlights
              </Button>
              <Button
                type="link"
                to="/contact"
                className=" w-5/6 place-content-center bg-white-300 text-black hover:bg-white-400 sm:w-fit"
                startIcon={
                  <FaEnvelope className="-ml-14 text-gray-900 sm:-ml-0" />
                }
                contactSubjectMessage={highlight?.attributes.title}
              >
                Contact us
              </Button>
            </div>
          </Inner>
        )}
      </Hero>
    </Page>
  );
};

export default HighlightPage;
