export const hexToColors: { [key: string]: string } = {};

export const colorToHex: { [key: string]: string } = {
  red: "#FF0000",
  blue: "rgb(76, 205, 255)",
  "th-blue": "#367588",
  "th-green": "#2E8B57",
  "light-grey": "#EEEEEE",
  white: "#FFFFFF",
  black: "#000000",
  darkBlue: "#00008B",
  darkGreen: "#013220",
  green: "#008000",
  ["blue-100"]: "rgb(76, 205, 255)",
  transparent: "transparent",
};

export default {
  primaryButton: {
    Blue: "#2160C7",
    DarkerBlue: "#1C4EA3",
  },
  coldThemeOne: {
    richBlack: "#0A090C",
    isabellineWhite: "#F0EDEE",
    darkTeal: "#07393C",
    teal: "#2C666E",
    middleBlue: "#90DDF0",
  },
  warmThemeOne: {
    richBlack: "#020202",
    beige: "#F2F3D9",
    lavender: "#BFB1C1",
    tyrianPurple: "#5E0035",
    raspberry: "#950952",
  },
  warmColdThemeOne: {
    rubyRed: "#A31621",
    amaranthRed: "#DB222A",
    beauBlue: "#BFDBF7",
    indigoDye: "#053C5E",
    metallicSeaweed: "#1F7A8C",
  },
  chartThemeOne: {
    darkBlue: "#173F5F",
    blue: "#20639B",
    teal: "#3CAEA3",
    yellow: "#F6D55C",
    red: "#ED553B",
  },
  chartThemeTwo: {
    darkBlue: "#006992",
    blue: "#08B2E3",
    teal: "#99EDCC", // green
    yellow: "#fbffc7",
    red: "#FF499E",
  },
  /* Rainbow */
  DarkGreen: "#066350",
  MediumGreen: "#02871F",
  LightGreen: "#80D8C6",
  LightBlue: "#2E46B6",
  DarkBlue: "#1F3263",
  Purple: "#503750",
  Yellow: "#FEC96C",
  Orange: "#C76D59",
  Red: "#F0655D",

  /* Grayscale */
  Black: "#000000",
  White: "#FFFFFF",
  Gray1: "#333333",
  Gray2: "#6B6B6B",
  Gray3: "#ABABAB",
  Gray4: "#CCCCCC",
  Gray5: "#DCDCDC",
  Gray6: "#EEEEEE",
  Gray7: "#FAFAFA",
  Transparent: {
    Black80: "rgba(0,0,0,0.8)",
    Black58: "rgba(0,0,0,0.58)",
    Black33: "rgba(0,0,0,0.33)",
    Black20: "rgba(0,0,0,0.2)",
    Black14: "rgba(0,0,0,0.14)",
    Black07: "rgba(0,0,0,0.07)",
    White20: "rgba(255,255,255,0.20)",
    White42: "rgba(255,255,255,0.42)",
    White67: "rgba(255,255,255,0.67)",
    White80: "rgba(255,255,255,0.80)",
    White86: "rgba(255,255,255,0.86)",
    White93: "rgba(255,255,255,0.93)",
    White98: "rgba(255,255,255,0.98)",
  },
};
