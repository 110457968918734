import { Copy, H1, H3, H5 } from "../ui/components/copy";
import { Section, Inner, Page } from "../ui/components/layout";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { scrollToTop } from "../util/scrolltotop";
import parse from "html-react-parser";
import PageContext from "../state";
import { format } from 'date-fns';

const Highlights = () => {
  const { highlights } = useContext(PageContext);

  return (
    <Page>
      <Section>
        <Inner>
          <H1 className="mb-14 max-w-[800px]">News and highlights</H1>
          <Copy className="mb-2 max-w-[700px] font-semibold leading-8">
            Download past Novitas Chem Solution NLGI and STLE Presentations:
          </Copy>
          <Copy className="mb-6 grid grid-cols-3 py-2 ">
            <Link
              to={"/uploads/polyurea-grease.pdf"}
              target="_blank"
              className="hover-pointer col-span-3 rounded-lg px-0 py-2 text-blue-300 underline hover:cursor-pointer hover:bg-gray-300 hover:bg-opacity-30 hover:text-blue-100 sm:px-2 "
              onClick={scrollToTop}
            >
              NLGI 2020 - Advancements of Polyurea Grease
            </Link>
            <Link
              to={"/uploads/specialty-additive.pdf"}
              target="_blank"
              className="hover-pointer col-span-3 rounded-lg px-0 py-2 text-blue-300 underline hover:cursor-pointer hover:bg-gray-300 hover:bg-opacity-30 hover:text-blue-100 sm:px-2 "
            >
              STLE 2018 – Specialty Additives
            </Link>
            <Link
              to={"/uploads/synthetic-base-oils.pdf"}
              target="_blank"
              className="hover-pointer col-span-3 rounded-lg px-0 py-2 text-blue-300 underline hover:cursor-pointer hover:bg-gray-300 hover:bg-opacity-30 hover:text-blue-100 sm:px-2 "
            >
              STLE 2017 – Synthetic Base Oils
            </Link>
          </Copy>
          <Copy className="p-2 font-semibold">Browse all :</Copy>

          <div className="grid-col-1 grid">
            {highlights.map((highlight, id) => (
              <div
                key={id}
                className="divide-y-1 min-h-32  max-h-64 divide-white-100 truncate whitespace-normal py-2 px-4 odd:border-y-2 hover:bg-gray-100 hover:bg-opacity-10 "
              >
                <Link to={`/company/highlights/${highlight.id}/${highlight.attributes.urlTitle}`}>
                  <div className="">
                    <Copy className="mt-2 text-sm">
                      {format(new Date(highlight.attributes.dateWritten), 'MMM dd, yyyy')}
                    </Copy>
                    <H3 className="text-blue-300 hover:text-blue-100 hover:underline ">
                      {highlight.attributes.title}
                    </H3>
                    <H5 className="mb-6   max-w-[900px] overflow-hidden  text-sm font-semibold sm:text-base ">
                      {highlight.attributes.articlePreview &&
                        parse(highlight.attributes.articlePreview)}
                    </H5>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </Inner>
      </Section>
    </Page>
  );
};
export default Highlights;
