import { Inner, Page, Section } from "../ui/components/layout";
import {
  FaLongArrowAltRight,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";
import Button from "../ui/components/button";
import { H4, Copy, H3, H2 } from "../ui/components/copy";
import { useLocation } from "react-router-dom";

const Contact = () => {
  const location = useLocation();
  const { contactSubjectMessage } = location.state ?? {};

  return (
    <Page>
      <Section className="lg:mt-6">
        <Inner>
          <div className="flex flex-col gap-5 lg:flex-row">
            <form
              className="order-2 flex w-full shrink flex-col gap-5 rounded-lg bg-white-300 p-4 lg:order-1"
              action="https://formspree.io/f/mbjelbjd"
              method="POST"
            >
              <H3>Reach out for more information</H3>
              <div className="grid grid-cols-fit-300 gap-5">
                <input
                  className="border-1 w-full rounded-lg border-white-400 p-3 focus:outline-none"
                  type="text"
                  placeholder="First Name"
                  id="first_name"
                  name="first_name"
                  required={true}
                />
                <input
                  className="border-1 w-full rounded-lg border-white-400 p-3 focus:outline-none"
                  type="text"
                  placeholder="Last Name"
                  id="last_name"
                  name="last_name"
                />
              </div>
              <div className="grid grid-cols-fit-300 gap-5">
                <input
                  className="border-1 w-full rounded-lg border-white-400 p-3 focus:outline-none"
                  type="email"
                  placeholder="Email"
                  id="email"
                  name="email"
                  required={true}
                />
                <input
                  className="border-1 w-full rounded-lg border-white-400 p-3 focus:outline-none"
                  type="tel"
                  placeholder="Phone"
                  id="telephone"
                  name="telephone"
                />
              </div>

              {contactSubjectMessage ? (
                <input
                  className="border-1 w-full rounded-lg border-white-400 p-3 focus:outline-none"
                  type="tel"
                  defaultValue={"Subject: " + contactSubjectMessage}
                  id="subject"
                  name="subject"
                />
              ) : (
                <input
                  className="border-1 w-full rounded-lg border-white-400 p-3 focus:outline-none"
                  type="tel"
                  placeholder="Subject or Product Type"
                  id="subject"
                  name="subject"
                />
              )}
              <textarea
                placeholder="Message"
                className="border-1 h-32 w-full rounded-lg border-white-400 p-3 focus:outline-none"
                id="message"
                name="message"
                required={true}
              />
              <Button
                type="submit"
                endIcon={<FaLongArrowAltRight className="text-white-300" />}
              >
                Send
              </Button>
            </form>
            <div className="order-1 w-[500px] min-w-[300px] lg:order-2">
              <H2 className="-inset-16   my-4 text-3xl font-bold ">
                Drop by our office
              </H2>
              <Copy className="">
                Novitas Chem operates in a dynamic and rapidly changing
                ecosystem. We'd love to see you at the plant!
              </Copy>

              <div className="flex flex-col justify-start gap-10 sm:flex-row lg:flex-col">
                <div className="mt-6 flex w-full min-w-full flex-row sm:min-w-[300px] lg:w-full">
                  <div className="mt-1.5 flex flex-col pr-3">
                    <FaMapMarkerAlt />
                  </div>
                  <div className="flex flex-col">
                    <H4 className="mb-2">Main Office</H4>
                    <Copy>
                      7001 Corporate Drive <br /> Suite 338 <br />
                      Houston, TX 77036
                    </Copy>
                  </div>
                </div>

                <div className="flex w-full min-w-full flex-row sm:mt-6 sm:min-w-[300px] lg:w-full">
                  <div className="mt-1.5 flex flex-col pr-3">
                    <FaPhoneAlt />
                  </div>
                  <div className="flex flex-col">
                    <H4 className="mb-2">Call Us</H4>
                    <Copy className="">Phone: (281){""} 213-0066</Copy>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Inner>
      </Section>
    </Page>
  );
};

export default Contact;
