import { Copy, H1, H3, H5 } from "./copy";
import { Section, Inner, Page } from "./layout";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { scrollToTop } from "../../util/scrolltotop";
import parse from "html-react-parser";
import PageContext from "../../state";
import { format } from 'date-fns';

const Highlights = () => {
  const { highlights } = useContext(PageContext);
  return (
    <div className="grid-col-1 grid">
      {highlights.map((highlight, id) => (
        <div
          key={id}
          className="divide-y-1 min-h-32  max-h-64 divide-white-100 truncate whitespace-normal py-2 px-4 odd:border-y-2 hover:bg-gray-100 hover:bg-opacity-10 "
        >
          <Link to={`/company/highlights/${highlight.id}/${highlight.attributes.urlTitle}`} className="no-underline">
            <div className="">
              <Copy className="mt-2 text-sm">
                {format(new Date(highlight.attributes.dateWritten), 'MMM dd, yyyy')}
              </Copy>
              <H3 className="text-blue-300 hover:text-blue-100 hover:underline ">
                {highlight.attributes.title}
              </H3>
              <H5 className="mb-6   max-w-[900px] overflow-hidden  text-sm font-semibold sm:text-base ">
                {highlight.attributes.articlePreview &&
                  parse(highlight.attributes.articlePreview)}
              </H5>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}

export default Highlights;