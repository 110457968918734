import { useMediaQuery } from '@mui/material';
import React from 'react';
import { ProductTableCell } from '../../../api/sitePages';
import { getColor } from '../../../util/dom';
import Markdown from './Markdown';

type TableProps = {
  rows: ProductTableCell[][];
  mobileRows?: ProductTableCell[][];
  style?: {
    border?: string,
    margin?: string
  }
}

const headerSpacing = `px-6 py-3`;
const cellSpacing = `px-4 py-2`;

const defaultStyles = {
  border: '1px solid #999',
  textAlign: 'center'
}

const getStyles = (tableStyles: any, cellStyles: any) => {
  return {
    ...defaultStyles,
    ...tableStyles,
    ...cellStyles
  }
}

const Display = ({ rows, style: tableStyle = {} }: TableProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  return (
    <table style={{
      tableLayout: 'fixed',
      ...(isSmallScreen ? {
        width: '100%',
        overflowX: 'auto',
        display: 'block'
      } : {})
    }}>
      <tbody>
        {rows.map((row, i) => {
          return (
            <tr>
              {row.map((cell, i) => {
                const {
                  text,
                  header = false,
                  colSpan = undefined,
                  rowSpan = undefined,
                  color: colorCode  = "#000000",
                  bgColor: bgColorCode = "#FFFFFF",
                  style = {},
                  markdownStyle = {},
                } = cell;
                const spacing = header ? headerSpacing : cellSpacing;
                return (
                  <td
                    className={`border ${spacing}`}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    style={{
                      backgroundColor: getColor(bgColorCode),
                      color: getColor(colorCode),
                      ...getStyles(tableStyle, style)
                    }}
                  >
                    <Markdown
                        data={text || ''}
                        style = {{
                          backgroundColor: getColor(bgColorCode),
                          color: getColor(colorCode),
                          ...markdownStyle
                        }}
                        // imageMaxHeight={250}
                      />
                    </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  );
}

const Table = ({ rows, mobileRows, style: tableStyle = {} }: TableProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  if (mobileRows && isSmallScreen) {
    return <Display rows={mobileRows} style={tableStyle} />
  }
  return <Display rows={rows} style={tableStyle} />
};

export default Table;