import React from 'react';
import { Link } from 'react-router-dom';
import { Media } from '../../../util/pageGraph';
import { Copy, H2 } from '../copy';

const MediaComponent = ({ name, ext, caption, url, previewUrl }: Media) => {
  return (
    <div className="border-solid border-white-300 bg-white-100 flex flex-row">
      <img src={previewUrl} className="cursor-pointer" />
      <a href={url} target={"_blank"}>
        <Copy>{caption}</Copy>
      </a>
    </div>
  );
}

export default MediaComponent;