import {
  PageAttribute,
  HighlightAttribute,
  ApiResponse,
  ContentResponse,
  apiUrl,
  fetchApi,
  errorHandler,
  sortWrittenDate,
  sortDescDate,
} from "./sitePages";

const SUMMARY_LENGTH = 200;
const shortenSummary = (body: string, start = 0) => {
  // if body length is shorter than summary length
  if (body.length < SUMMARY_LENGTH) {
    return body;
  }
  // if we start at 0
  if (start === 0) {
    return `${body.slice(start, SUMMARY_LENGTH - 3)}...`;
  }
  if (body.length - start <= SUMMARY_LENGTH) {
    return `...${body.slice(body.length - SUMMARY_LENGTH - 3)}`;
  }
  return `...${body.slice(start, start + SUMMARY_LENGTH - 6)}...`;
};

const cleanHtmlTags = (str: string) => {
  const tags = ["<br/>"];
  return tags.reduce((cleaned, tag) => {
    return cleaned.replaceAll(tag, "");
  }, str);
};

const getSearchSummary = (
  searchUrl: string,
  attributes: PageAttribute | HighlightAttribute
): string => {
  if ((attributes as HighlightAttribute).articlePreview) {
    return cleanHtmlTags((attributes as HighlightAttribute).articlePreview);
  }
  const searchWord = decodeURIComponent(
    searchUrl.split("[$containsi]=")[1]
  ).toLowerCase();
  const body = (attributes as PageAttribute).body
    .map((b) => {
      let text = "";
      if (b.richtext) {
        text = b.richtext;
      }
      const table = (
        b.product_table?.data?.attributes?.data?.rows || []
      ).flat();
      text += `${table.map((cell) => cell.text).join(" ")}`;
      return cleanHtmlTags(text);
    })
    .join(" ")
    .toLowerCase();
  if (body.length < SUMMARY_LENGTH) {
    return body;
  }
  if (body.includes(searchWord)) {
    // find search word area
    const startIndex = body.indexOf(searchWord);
    const endIndex = startIndex + searchWord.length;
    const surroundingChars = Math.floor(
      (SUMMARY_LENGTH - searchWord.length) / 2
    );
    if (body.length - endIndex < surroundingChars) {
      return shortenSummary(body, body.length - SUMMARY_LENGTH);
    }
    if (
      startIndex >= surroundingChars &&
      body.length - endIndex >= surroundingChars
    ) {
      // perfectly centered
      return shortenSummary(body, startIndex - surroundingChars);
    }
  }
  // if body does not include search, it's probably the title
  // in which case just return the start of summary
  return shortenSummary(body);
};

export type SearchProps = {
  createdAt?: string;
  title: string;
  summary: string;
  url: string;
};
const dedupeSearchResults = <
  T extends ContentResponse<PageAttribute> | ContentResponse<HighlightAttribute>
>(
  results: (ApiResponse<T[]> & { searchUrl: string })[]
) => {
  const searchMap: { [key: number]: SearchProps } = {};
  // dedupe
  results.forEach(({ data, searchUrl }) => {
    data.forEach(({ id, attributes }) => {
      searchMap[id] = {
        createdAt: (attributes as HighlightAttribute).dateWritten,
        title: attributes.title,
        summary: getSearchSummary(searchUrl, attributes),
        url:
          (attributes as PageAttribute).url ??
          `/company/highlights/${id}/${
            (attributes as HighlightAttribute).urlTitle
          }`,
      };
    });
  });
  return Object.values(searchMap);
};

const titleFilter = "&filters[title][$containsi]=";
const bodyFilter = "&filters[body][richtext][$containsi]=";
const searchPages = (searchWord: string) => {
  const searchBase = `${apiUrl}/pages?populate[parent]=*&populate[body][populate]=*`;
  return [
    `${searchBase}${titleFilter}${searchWord}&${sortDescDate}`,
    `${searchBase}${bodyFilter}${searchWord}&${sortDescDate}`,
  ];
};

const searchArticles = (searchWord: string) => {
  const articleContent = `${apiUrl}/blogs?filters[articleContent][$containsi]=${searchWord}&${sortWrittenDate}`;
  const articleTitle = `${apiUrl}/blogs?filters[title][$containsi]=${searchWord}&${sortWrittenDate}`;
  return [articleContent, articleTitle];
};

// TODO: handle pagination
export async function search(
  searchWord: string
): Promise<{ pages: SearchProps[]; highlights: SearchProps[] }> {
  try {
    const pageResults = await Promise.all(
      searchPages(searchWord).map(async (searchUrl) => {
        const data = await fetchApi<ContentResponse<PageAttribute>[]>(
          searchUrl
        );
        return { ...data, searchUrl };
      })
    );

    const articleResults = await Promise.all(
      searchArticles(searchWord).map(async (searchUrl) => {
        const data = await fetchApi<ContentResponse<HighlightAttribute>[]>(
          searchUrl
        );
        return { ...data, searchUrl };
      })
    );

    return {
      pages: dedupeSearchResults(pageResults),
      highlights: dedupeSearchResults(articleResults),
    };
  } catch (error) {
    throw errorHandler(error);
  }
}
