import { twMerge } from "tailwind-merge";
import { colorToHex, hexToColors } from "../ui/styles/colors";

export function classFilter(...classes: string[]) {
  return twMerge(classes.filter(Boolean).join(" "));
}

const DEFAULT_HEX = "#666666";
export const getColor = (color: string, className: boolean = false) => {
  if (className && color.startsWith("#")) {
    if (hexToColors[color]) {
      return hexToColors[color];
    }
    return `[${color}]`;
  }
  if (!className && !color.startsWith("#")) {
    if (colorToHex[color]) {
      return colorToHex[color];
    }
    // default white
    return DEFAULT_HEX;
  }
  return color;
};
