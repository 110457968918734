import { FaEnvelope, FaLocationArrow, FaPhone } from "react-icons/fa";
import { Copy } from "./copy";
import { Inner, Section } from "./layout";

const Footer: React.FC = () => {
  return (
    <Section className="bg-white-300">
      <Inner className="items-flex-start lg:items-flex-start flex flex-col gap-20 md:flex-row md:items-end lg:flex-col xl:flex-row xl:items-end">
        <div className="flex w-full flex-col gap-5">
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-0">
            <a
              href="mailto:supports@novitaschem.com"
              className="no-underline flex flex-row items-end gap-3 bg-white-100 hover:bg-white-50 rounded-full p-4 w-[350px]"
            >
              <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-blue-500">
                <FaEnvelope className="text-xl text-white-50" />
              </div>
              <div className="flex flex-col">
                <Copy className="leading-0 mb-1 font-bold">Email</Copy>
                <Copy className="leading-0  mb-0">
                  supports@novitaschem.com
                </Copy>
              </div>
            </a>
            <a
              href="tel:2812130066"
              className="no-underline flex flex-row items-end gap-3  bg-white-100 hover:bg-white-50 rounded-full p-4 w-[350px]"
            >
              <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full  bg-blue-500">
                <FaPhone className="rotate-90 text-xl text-white-50" />
              </div>
              <div className="flex flex-col">
                <Copy className="leading-0 mb-1 font-bold">Phone</Copy>
                <Copy className="leading-0 mb-0">(281) 213-0066</Copy>
              </div>
            </a>
            <a
              href="/contact"
              className="no-underline flex flex-row items-end gap-3  bg-white-100 hover:bg-white-50 rounded-full p-4 w-[350px]"
              // target="_blank"
              // rel="noreferrer"
            >
              <div className="flex h-[50px] w-[50px] items-center justify-center rounded-full bg-blue-500">
                <FaLocationArrow className="text-xl text-white-50" />
              </div>
              <div className="flex flex-col">
              <Copy className="leading-0 mb-1 font-bold">Contact Us</Copy>
                <Copy className="leading-0 mb-0">Get in touch today!</Copy>
              </div>
            </a>
          </div>
          <Copy className="mt-10 block self-center">{`Copyright © ${new Date().getFullYear()} Novitas. (CS)`}</Copy>
        </div>
      </Inner>
    </Section>
  );
};

export default Footer;
