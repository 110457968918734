import { createContext, useEffect, useState } from "react";
import { ContentResponse, getPages, HighlightAttribute, CarouselAttribute } from './api/sitePages';
import Loading, { PageLoading } from "./ui/components/loading";
import PageGraph, { NavItem, PageData } from "./util/pageGraph";
import AppRouter from "./AppRouter";
import PageContext from "./state";

function App() {
  const [highlights, setHighlights] = useState<ContentResponse<HighlightAttribute>[]>([]);
  const [pageGraph, setPageGraph] = useState<PageGraph>();
  const [carousel, setCarousel] = useState<ContentResponse<CarouselAttribute>[]>([]);

  useEffect(() => {
    // get page graph
    getPages().then((r) => {
      const { pageGraph, highlights, carousel } = r;
      setPageGraph(pageGraph);
      setHighlights(highlights);
      setCarousel(carousel);
    })
  }, []);

  if (!pageGraph) {
    return <PageLoading />;
  }

  return <PageContext.Provider value={{ pageGraph, highlights, carousel }}>
      <AppRouter />
    </PageContext.Provider>
}

export default App;
