import React, { useContext } from 'react';
import PageGraph, { NavItem, PageData } from '../../../util/pageGraph';
import { Page, Inner, DynamicSection, Hero, Section } from '../layout';
import ReactMarkdown from 'react-markdown'
import { H3, H2, H1, Copy } from '../copy';
import Table from './Table';
import Markdown from './Markdown';
import SideNav from '../sideNav';
import { Helmet } from "react-helmet";
import PageContext from '../../../state';
import { Link } from 'react-router-dom';
import MediaComponent from './Media';
import SubjectContact from '../subjectContact';
import Highlights from '../highlights';
import { BlockType } from '../../../api/sitePages';
import { Button } from '@mui/material';
import Body from './Body';

type InnerMenuProps = {
  children: string[];
};

const InnerMenu = ({ children }: InnerMenuProps) => {
  const { pageGraph } = useContext(PageContext);
  const navItems = children.map((child) => {
    const pageData = pageGraph.get(child);
    return {
      url: pageData.url,
      name: pageData.title
    }
  });
  return (
    <SideNav navItems={navItems} />
  );
}

type ContentPageProps = {
  pageData: PageData;
}

const ContentPage = ({
  pageData
}: ContentPageProps) => {
  const {
    url,
    parent,
    children,
    body,
    title,
    meta,
    breadcrumb: mainBreadcrumb
  } = pageData;

  const breadcrumb = [ ...mainBreadcrumb ];
  breadcrumb.pop();
  return (
    <Page>
      <Helmet>
        {meta?.title
          ? <title>Novitas Chem - {meta.title}</title>
          : <title>Novitas Chem - {title}</title>
        }
        {meta?.description && <meta name="description" content={meta.description} />}
        {meta?.keywords && <meta name="keyword" content={meta.keywords} />}
      </Helmet>
      <Section>
        <Inner>
          <div className="pb-4">
            <span><Link to={'/'}>Home</Link>{' / '}</span>
            {breadcrumb.length > 0 && breadcrumb.map(crumb => {
              return (
                <span>
                  <Link to={crumb.url}>{crumb.name}</Link>{' '}/{' '}
                </span>
              );
            })}
          </div>
          <H1>{title}</H1>
          {children.length > 0 && <InnerMenu children={children} />}
          <Body body={body} title={title} />
        </Inner>
      </Section>
    </Page>
  );
}

export default ContentPage;