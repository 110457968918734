import { useMediaQuery } from "@mui/material";
import { useContext, useState } from "react";
import {
  FaBars,
  FaChevronDown,
  FaChevronRight,
  FaEnvelopeOpenText,
  FaTimes,
} from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/images/novitas-logo.png";
import { classFilter } from "../../util/dom";
import { scrollToTop } from "../../util/scrolltotop";
import Button from "./button";
import { Copy } from "./copy";
import { Inner } from "./layout";
import Searchbar from "./dynamic/search/searchbar";
import PageContext from "../../state";
import { NavItem } from "../../util/pageGraph";

// const NavMobileSubLink: React.FC<NavMobileSubLinkProps> = ({ sub, close }) => (
//   <li className="justify-flex-start hover:bg-white-700 group relative flex w-full flex-col items-center gap-3 rounded-md px-3 py-1">
//     <Link to={sub.to} className="w-full" onClick={close}>
//       <Copy className="text-lg mb-0 font-semibold">{sub.title}</Copy>
//     </Link>

//     {sub.subOfSub && (
//       <ul className="mt-5 flex w-full flex-col gap-4 rounded-md">
//         {sub.subOfSub.map((sub, i) => (
//           <NavMobileSubLink sub={sub} close={close} key={i} />
//         ))}
//       </ul>
//     )}
//   </li>
// );


const NavDesktop = () => {
  const { pageGraph } = useContext(PageContext);
  const [menuActive, setMenuActive] = useState(false);
  return (
    <nav className="mt-5 hidden md:flex lg:flex items-center">
      <ul
        className="flex flex-row items-center justify-start gap-1"
        onClick={scrollToTop}
        onMouseEnter={() => setMenuActive(true)}
      >
        <li className="pointer-cursor justify-flex-start group relative flex w-fit flex-row items-center gap-3 rounded-md bg-white-200 bg-opacity-0 px-5 py-1 transition-all duration-300 ease-in-out hover:bg-opacity-90">
          <Link to="/" className="no-underline">
            <div className="flex flex-row items-center gap-2">
              <Copy
                className={classFilter(
                  "mb-0 font-semibold text-gray-500",
                  "group-hover:text-blue-500"
                )}
              >
                Home
              </Copy>
            </div>
          </Link>
        </li>
        {pageGraph.nav.map((navItem, index) => {
          return <DesktopNav 
            key={index}
            navItem={navItem}
          />
        })}
        <Button
          type="link"
          to="/contact"
          className="px-4 ml-2 bg-transparent text-gray-800 shadow-none hover:shadow-md "
          endIcon={<FaEnvelopeOpenText className="text-white-500" />}
        >
          Contact
        </Button>
      </ul>
      <Searchbar open={true} />
    </nav>
  )
}

const NavDesktopLinks = ({ items, nestLevel = 0, active, parent }: { parent?: NavItem; items: NavItem[], nestLevel?: number, active?: string }) => {
  const margin = nestLevel === 0 ? 'mt-1' : 'ml-5';
  const left = nestLevel === 0 ? 'left-0' : 'left-[250px]';
  const top = nestLevel === 0 ? 'top-full' : 'top-0';
  const [showChildren, setShowChildren] = useState(false);
  const navigate = useNavigate(); 
  const shouldShow = active === parent?.url;
  const [isActive, setIsActive] = useState('');

  return (
    <div className={`${top} absolute w-[250px] ${left} ${shouldShow ? 'block' : 'hidden'} ease-in-out duration-300 transition-all`}>
      <ul className={`flex w-full flex-col gap-1 rounded-md border-[.5px] border-solid border-white-300 bg-white-200`}>
        {items.map((item) => {
          return (
            <li 
              className={`cursor-pointer justify-flex-start relative flex w-full flex-row items-center gap-3 rounded-md px-3 py-1 hover:bg-white-400`}
              onMouseEnter={() => { console.log('in', item.url); setIsActive(item.url) }}
              onMouseLeave={() => { console.log('out', item.url); setIsActive('')}}
              onClick={() => { setIsActive('') }}
            >
              <Link to={item.url} className="w-full no-underline">
                <div className="flex flex-row items-center gap-2"
                >
                  <Copy className={`mb-0 font-semibold hover:text-blue-500`}>
                    {item.title}
                  </Copy>
                  {item.children.length > 0 && (
                    <FaChevronRight
                      className={classFilter(`text-gray-500 hover:text-blue-500`)}
                    />
                  )}
                  {item.children.length > 0 && (
                    <NavDesktopLinks
                      items={item.children}
                      nestLevel={nestLevel + 1}
                      parent={item}
                      active={isActive}
                    />
                  )}
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  ) 
}
const DesktopNav = ({ navItem }: { navItem: NavItem, nestLevel?: number, }) => {
  const [isActive, setIsActive] = useState('');

  return (
    <li
      className="cursor-pointer justify-flex-start relative flex w-fit flex-row items-center gap-3 rounded-md bg-white-200 bg-opacity-0 px-5 py-1 transition-all duration-300 ease-in-out hover:bg-opacity-90"
      onMouseEnter={() => { console.log('in', navItem.url); setIsActive(navItem.url) }}
      onMouseLeave={() => { console.log('out', navItem.url); setIsActive('')}}
      onClick={() => setIsActive('')}
    >
      <Link to={navItem.url} className="no-underline">
        <div
          className="flex flex-row items-center gap-2"
        >
          <Copy
            className={classFilter(
              "mb-0 font-semibold text-gray-500",
              "hover:text-blue-500"
            )}
          >
            {navItem.title}
          </Copy>
          {navItem.children.length > 0 && (
            <FaChevronDown
              className={classFilter("text-gray-500 hover:text-blue-500")}
            />
          )}
          {navItem.children.length > 0 && (
            <NavDesktopLinks
              items={navItem.children}
              nestLevel={0}
              active={isActive}
              parent={navItem}
            />
          )}
        </div>
      </Link>
    </li>
  );
}

const NavMobileLink = ({ navItem, isTopLevel = false, close }: { navItem: NavItem, isTopLevel: boolean, close: () => void }) => {
  const [expanded, setExpanded] = useState(false);
  if (isTopLevel) {
    return (
      <li
        className={classFilter(
          "flex w-full flex-col rounded-md py-2 px-2 transition-all duration-300 ease-in-out",
          expanded ? "bg-white-700 bg-opacity-50" : ""
        )}
        onClick={() => {
          if (navItem.children.length > 0) setExpanded(!expanded);
        }}
      
      >
      <div className="flex w-full flex-row items-center justify-between">
        <Link to={navItem.url} onClick={close} className="no-underline text-black">
          <Copy
            className={classFilter("text-white-500 mb-0 text-xl font-semibold")}
          >
            {navItem.title}
          </Copy>
        </Link>
        {navItem.children.length > 0 && (
          <FaChevronDown
            className={classFilter(
              "text-white-500 text-xl transition-all duration-300 ease-in-out",
              expanded ? "" : "-rotate-90"
            )}
          />
        )}
      </div>
      {navItem.children.length > 0 && expanded && (
        <ul className="mt-5 flex w-full flex-col gap-4 rounded-md">
          {navItem.children.map((child, i) => (
            <NavMobileLink
              navItem={child}
              isTopLevel={false}
              close={close}
              key={i}
            />
          ))}
        </ul>
      )}
      </li>
    );
  }

  return (
    <li className="justify-flex-start hover:bg-white-700 group relative flex w-full flex-col items-center gap-3 rounded-md pl-6">
      <Link to={navItem.url} className="w-full no-underline" onClick={close}>
        <Copy className="text-lg mb-0 font-semibold">{navItem.title}</Copy>
      </Link>

      {navItem.children.length > 0 && (
        <ul className="my-2 flex w-full flex-col gap-4 rounded-md">
          {navItem.children.map((child, i) => (
            <NavMobileLink
              navItem={child}
              isTopLevel={false}
              close={close}
              key={i}
            />
          ))}
        </ul>
      )}
    </li>
  );
}

const MobileMenu = ({ close }: { close: () => void }) => {
  const { pageGraph } = useContext(PageContext);
  return (
    <nav
      className={classFilter(
        "absolute top-0 right-0 flex h-fit min-h-screen w-screen flex-col items-end bg-white-200 pr-3 pb-3 pt-14 transition-all duration-300 ease-in-out md:hidden lg:hidden"
      )}
    >
      <div
        className="hover:bg-white-500 w-fit cursor-pointer rounded-full bg-opacity-5 p-2 hover:bg-opacity-5"
        onClick={scrollToTop}
      >
        <FaTimes
          className="text-white-500 text-2xl"
          onClick={() => close()}
        />
      </div>
      <ul
        className="flex w-full flex-col items-start justify-between gap-5"
        onClick={scrollToTop}
      >
        <li
          className={classFilter(
              "flex w-full flex-col rounded-md py-2 px-2 transition-all duration-300 ease-in-out"
            )}
          >
          <div className="flex w-full flex-row items-center justify-between">
            <Link to="/" onClick={close} className="no-underline text-black">
              <Copy
                className={classFilter("text-white-500 mb-0 text-xl font-semibold")}
              >
                Home
              </Copy>
            </Link>
          </div>
        </li>
        {pageGraph.nav.map((navItem, index) => {
          return <NavMobileLink 
            key={index}
            navItem={navItem}
            isTopLevel={true}
            close={close}
          />
        })}
      </ul>
    </nav>
  );
}
const MobileHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const close = () => setMenuOpen(false);
  return (
    <div className="flex md:hidden lg:hidden items-center">
      <Searchbar open={false} />
      <div
        className="cursor-pointer rounded-full p-2 hover:bg-black hover:bg-opacity-5"
        onClick={scrollToTop}
      >
        <FaBars
          className={classFilter("text-2xl text-gray-500")}
          onClick={() => setMenuOpen(true)}
        />
      </div>

      {menuOpen && <MobileMenu close={close} />}
    </div>
  );
}

export const headerHeight = "64px"

const Header: React.FC = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  // const pageLocation = useLocation();

  // const filteredNavigationItems = NavItems.filter((item) => item.to !== "/");

  const isSmallScreen = useMediaQuery("(max-width: 550px)");

  return (
    <div className={`top-0 left-0 z-50 flex h-[118px] w-full flex-col items-center bg-white-100/0`}>
      {/* Banner */}
      <div className="flex w-full flex-row justify-center bg-blue-500 px-1 py-2">
        <Link
          to={"/company/highlights"}
          className="mb-0 scroll-auto text-center font-semibold text-white-50"
        >
          {isSmallScreen
            ? "See the latest News & Highlights From Novitas"
            : "See the latest News & Highlights From Novitas Chem Solutions"}
        </Link>
      </div>
      <div className={`w-full max-w-[1200px] flex flex-row items-center h-[${headerHeight}] bg-white-100/0 justify-between px-2 py-3`}>
        {/* logo  */}
        <div>
          <Link to="/" className="menu-logo" onClick={scrollToTop}>
            <img
              src={Logo}
              alt="Novitas Chem Solutions Logo"
              className="w-[130px] lg:w-[180px]"
            />
          </Link>
        </div>
        {/* desktop menu */}
        <NavDesktop />

        {/* mobile dropdown */}
        <MobileHeader />
      </div>
    </div>
  );
  return (
    <div className="top-0 left-0 z-50 flex h-[118px] w-full flex-col items-center bg-white-100/0">

      <Inner className="flex flex-row items-center justify-between py-3 px-2">
        <div>
          <Link to="/" className="menu-logo" onClick={scrollToTop}>
            <img
              src={Logo}
              alt="Novitas Chem Solutions Logo"
              className="w-[130px] lg:w-[180px]"
            />
          </Link>
        </div>

        {/* desktop menu */}

        <nav className="mt-5 hidden lg:flex">
          {/* {pageLocation.pathname === "/" ? (
            <ul
              className="flex flex-row items-center justify-end gap-1"
              onClick={scrollToTop}
            >
              {filteredNavigationItems.map((item, i) => (
                <NavDesktopLink item={item} key={i} />
              ))}
              <Button
                type="link"
                to="/contact"
                className="ml-2 bg-transparent text-gray-800 shadow-none hover:shadow-md "
                endIcon={<FaEnvelopeOpenText className="text-white-500" />}
              >
                Contact
              </Button>
            </ul>
          ) : ( */}
            <ul
              className="flex flex-row items-center justify-end gap-1"
              onClick={scrollToTop}
            >
              {/* {NavItems.map((item, i) => (
                <NavDesktopLink item={item} key={i} />
              ))} */}
              <Button
                type="link"
                to="/contact"
                className="ml-2 bg-transparent text-gray-800 shadow-none hover:shadow-md "
                endIcon={<FaEnvelopeOpenText className="text-white-500" />}
              >
                Contact
              </Button>
            </ul>
          {/* )} */}
        </nav>

        <div onClick={scrollToTop} className="mt-4 hidden lg:flex">
          <Searchbar open={false} />
        </div>

        {/* mobile menu */}


      </Inner>
    </div>
  );
};

export default Header;
