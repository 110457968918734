import colors from "./colors";

const theme = {
  contentWidth: 1200,
  spacing: {
    xsmall: 5,
    small: 8,
    standard: 24,
  },
  fontSizes: {
    xsmall: "0.8em",
    xsmall2: "0.9em",
    small: "1.0em",
    medium: "1.25em",
    large: "1.563em",
    xlarge: "1.953em",
    x2large: "2.441em",
    x3large: "3.052em",
    x4large: "3.815em",
    x5large: "4.768em",
  },
  backgroundColor: colors.White,
  backgroundDarkColor: colors.DarkGreen,
  sideBarBackgroundColor: colors.DarkBlue,
  primaryColor: colors.Transparent.Black80,
  primaryDarkColor: colors.Transparent.White98,
  secondaryColor: colors.Transparent.Black58,
  secondaryDarkColor: colors.Transparent.White80,

  /* Buttons */
  primaryButton: {
    buttonColor: colors.primaryButton.Blue,
    borderColor: colors.primaryButton.Blue,
    textColor: colors.Transparent.White98,
    buttonHoverColor: colors.primaryButton.DarkerBlue,
    borderHoverColor: colors.primaryButton.DarkerBlue,
    textHoverColor: colors.White,
    buttonActiveColor: colors.primaryButton.DarkerBlue,
    borderActiveColor: colors.primaryButton.DarkerBlue,
  },

  secondaryButton: {
    buttonColor: colors.Yellow,
    borderColor: "#DAAC5C",
    textColor: colors.Transparent.Black80,
    buttonHoverColor: "#FED564",
    borderHoverColor: "#E9C056",
    textHoverColor: colors.Transparent.Black80,
    buttonActiveColor: colors.Yellow,
    borderActiveColor: "#D5A85A",
    textActiveColor: colors.Transparent.Black58,
  },

  flatLightButton: {
    textColor: colors.Red,
    textHoverColor: "#FE170D",
    textActiveColor: "#EB635B",
  },

  flatDarkButton: {
    textColor: colors.Yellow,
    textHoverColor: "#FEEE54",
    textActiveColor: colors.Yellow,
  },

  /* Horizontal rules */
  lightHRColor: colors.Transparent.Black20,

  /* Input Labels */
  labelColor: colors.Transparent.Black80,
  labelColorHover: colors.Black,

  /* Inputs */
  inputBackgroundColor: colors.Transparent.White80,
  inputBorderColor: colors.Transparent.Black14,
  inputBorderColorHover: colors.Transparent.Black33,
  inputColor: colors.Transparent.Black80,
  inputColorFocus: colors.Black,
  inputHelperTextColor: colors.Transparent.Black58,
  inputErrorColor: colors.Orange,

  inputSizes: {
    xsmall: 3,
    small: 5,
    medium: 6,
    mediumLarge: 10,
    large: 15,
  },

  /* Paper */
  paperColor: colors.White,
  paperShadowColor: colors.Transparent.Black14,

  /* Links */
  lightLinkColor: colors.LightBlue,
  darkLinkColor: colors.Yellow,
  lightLinkHoverColor: colors.DarkBlue,
  darkLinkHoverColor: colors.Yellow,

  /* Link Icon */
  lightLinkIconColor: colors.coldThemeOne.darkTeal,
  darkLinkIconColor: colors.coldThemeOne.richBlack,
  lightLinkIconHoverColor: colors.coldThemeOne.teal,
  darkLinkIconHoverColor: colors.coldThemeOne.darkTeal,

  /* Standards */
  fonts: {
    primary: "Lato Regular",
    title: "NunitoSans Black",
    bold: "NunitoSans Bold",
    light: "NunitoSans Light",
    impact: "Concert One Regular",
  },
  baseFontSize: "16px",
  colors,
  borderRadius: "4px",

  breakpoints: {
    widgetUp: "@media (min-width: 300px)",
    mobileUp: "@media (min-width: 480px)",
    smallTabletUp: "@media (min-width: 600px)",
    tabletUp: "@media (min-width: 768px)",
    tabletLandscapeUp: "@media (min-width: 880px)",
    desktopUp: "@media (min-width: 1200px)",
    largeDesktopUp: "@media (min-width: 1440px)",
    desktopDown: "@media (max-width: 1200px)",
    tabletLandscapeDown: "@media (max-width: 880px)",
    tabletDown: "@media (max-width: 768px)",
    smallTabletDown: "@media (max-width: 600px)",
    mobileDown: "@media (max-width: 480px)",
    smallMobileDown: "@media (max-width: 320px)",
  },
  breakpointNums: {
    tablet: 768,
  },
};

export default theme;
