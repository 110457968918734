import { classFilter } from "../../util/dom";

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement>;
type CopyProps = React.HTMLAttributes<HTMLParagraphElement>;
type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement>;

const BASE_HEADING_CLASS = "font-semibold tracking-tight mb-5";

const H1: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h1
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-2xl md:text-3xl lg:text-3xl xl:text-4xl",
        className ?? ""
      )}
    >
      {props.children}
    </h1>
  );
};
const H2: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h2
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-xl md:text-2xl lg:text-2xl xl:text-3xl",
        className ?? ""
      )}
    >
      {props.children}
    </h2>
  );
};

const H3: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h3
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-l md:text-xl lg:text-xl xl:text-2xl",
        className ?? ""
      )}
    >
      {props.children}
    </h3>
  );
};
const H4: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h4
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-m md:text-l lg:text-l xl:text-xl",
        className ?? ""
      )}
    >
      {props.children}
    </h4>
  );
};

const H5: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h5
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "text-md sm:text-base",
        className ?? ""
      )}
    >
      {props.children}
    </h5>
  );
};

const H6: React.FC<HeadingProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <h6
      {...passthrough}
      className={classFilter(
        BASE_HEADING_CLASS,
        "sm:text-md text-sm",
        className ?? ""
      )}
    >
      {props.children}
    </h6>
  );
};

const Copy: React.FC<CopyProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <p
      {...passthrough}
      className={classFilter(
        "mb-2 text-base leading-8",
        className ?? ""
      )}
    >
      {props.children}
    </p>
  );
};

const Label: React.FC<LabelProps> = (props) => {
  const { className, ...passthrough } = props;
  return (
    <label
      {...passthrough}
      className={classFilter(
        "text-sm font-bold leading-8 text-gray-800",
        className ?? ""
      )}
    >
      {props.children}
    </label>
  );
};

export { H1, H2, H3, H4, H5, H6, Copy, Label };
