import { FaLongArrowAltRight } from "react-icons/fa";
import Image from "../ui/components/image";
import { H1, Copy, H2, H3, H4 } from "../ui/components/copy";
import { Inner, Page, Section } from "../ui/components/layout";
import Button from "../ui/components/button";
import ResponsiveCarousel from "../ui/components/carousel";
import { scrollToTop } from "../util/scrolltotop";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import PageContext from "../state";
import { format } from 'date-fns';
import styled from "@emotion/styled";
import theme from "../ui/styles/theme";

const Home = () => {
  const { highlights } = useContext(PageContext);
  const navigate = useNavigate();
  return (
    <Page>
      <Section>
        <CarouselContainer>
          <ResponsiveCarousel />
        </CarouselContainer>
      </Section>

      <Section>
        <Inner>
          <Section className="mb-6">
            <H2 className="text-center uppercase">
              Experience the <span className="text-blue-500">Novitas</span>{" "}
              difference.
            </H2>
            <video controls width="1000" poster="https://novitaschem-media.s3.amazonaws.com/video_preview_2fb68b7631.png">
              <source src="https://novitaschem-media.s3.amazonaws.com/final_video_1_093fb9cde3.mp4" />
            </video>
          </Section>

          <div className="grid grid-cols-12 md:divide-x">
            <div className="col-span-12 md:col-span-7">
              <H3 className="mb-5 mt-12 font-semibold sm:p-2">
                Novitas Chem Solutions,
              </H3>
              <Copy className="mb-5 sm:p-2">
                Through a worldwide sales network, we offer an extensive range of
                additives, Synthetic base oils, and thickeners tailored to
                specific and demanding applications for the lubricant and grease
                industry.
              </Copy>
              <Copy className="mb-5 sm:p-2">
                Novitas Chem Solutions, LLC began in 2006 by providing
                consulting services with an emphasis on innovation and
                business solutions to the lubricant industry. Today, through
                extensive research, development, and dedicated
                manufacturing, we offer premium Specialty Additives (SA),
                Specialty Thickeners (ST) and Synthetic Base Oils (SBO)
                products for the industrial, metalworking, and grease
                markets.
              </Copy>
              <Copy className="mb-5 sm:p-2">
                <Link to="/company" onClick={scrollToTop}>
                  Click to learn more about Novitas!
                </Link>
              </Copy>
            </div>
            <ProductNewsContainer className="col-span-12 md:col-span-5 md:pl-8">
              <div>
                <H3 className=" mt-12 font-semibold underline ">
                  Product Highlights & News
                </H3>
                {highlights.slice(0, 3).map((highlight, id) => (
                  <div
                    key={id}
                    onClick={() => navigate(`/company/highlights/${highlight.id}/${highlight.attributes.urlTitle}`)}
                    className="cursor-pointer divide-y-1 min-h-32 divide-white-100 truncate whitespace-normal py-4 odd:border-y-2 hover:bg-gray-100 hover:bg-opacity-10 "
                  >
                    <div>
                      <H4 className="text-blue-300 hover:text-blue-100 hover:underline mb-1">
                        {highlight.attributes.title}
                      </H4>
                      <Copy className="text-sm">
                        {format(new Date(highlight.attributes.dateWritten), 'MMM dd, yyyy')}
                      </Copy>
                      <Copy className="max-w-[900px] text-sm">
                        {highlight.attributes.articlePreview &&
                          parse(highlight.attributes.articlePreview)}
                      </Copy>
                    </div>
                  </div>
                ))}
              </div>
            </ProductNewsContainer>
          </div>
        </Inner>
      </Section>
      <Section className="relative h-fit min-h-[75vh] justify-center pb-10">
        <Image
          src="https://novitaschem-media.s3.amazonaws.com/novitas_landing_dd0954a3b4.jpeg"
          alt="Novitas Chem"
          className="pointer-events-none absolute top-0 left-0 -z-10 h-full w-full object-cover"
        />
        <Inner className=" ">
          <H1 className="make-a-shadow -mt-8 max-w-[500px] text-blue-600 sm:-mt-16 lg:max-w-[500px] xl:max-w-[600px]">
            Bringing new, innovative chem solutions to the market place.
          </H1>
          <div className="flex flex-col items-start gap-5 md:flex-row">
            <Button
              type="link"
              to="/products"
              endIcon={<FaLongArrowAltRight className="text-white-500" />}
              className="w-[200px] md:w-fit"
            >
              Our Products
            </Button>
            <Button
              type="link"
              to="/company"
              className="w-[200px] bg-white-50 text-black hover:bg-white-100 md:w-fit"
            >
              More About Us
            </Button>
          </div>
        </Inner>
      </Section>
    </Page>
  );
};

export default Home;

const CarouselContainer = styled('div')`
  padding-left: 0;
  padding-top: ${theme.spacing.standard}px;
`;

const ProductNewsContainer = styled('div')`
  // @media (min-width: 550px) {
  //   max-height: 600px;
  //   overflow-y: scroll
  // }
`;