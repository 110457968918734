import React from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../util/scrolltotop";

type NavItem = {
  url: string,
  name: string
}
type Props = {
  level?: number
  navItems: NavItem[]
};

const SideNav = ({ navItems, level = 0 }: Props) => {
  const levelDivs = Array(level).fill(<div className="pl-2 mt-2 hidden font-semibold sm:block">{">"}</div>);
  return (
      <div className="col-span-12 -mt-2 mb-6 grid grid-cols-12 sm:mb-10 sm:ml-10 sm:flex ">
        {levelDivs.map(sep => sep)}
        {
          navItems.map(({ url, name }, index) => {
            return (
              <>
              <Link
                type="link"
                to={url}
                className=" text-lg ... col-span-12 ml-6 mt-2 truncate font-semibold  text-blue-500 hover:text-blue-200 sm:mx-2 sm:font-bold  md:col-span-2"
                onClick={scrollToTop}
              >
                {name}
              </Link>
              {(index < navItems.length - 1) && <div className="mt-2 hidden font-semibold sm:block">|</div>}
              </>
            )
          })
        }
      </div>
  );
};

export default SideNav;
