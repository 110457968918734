import React from 'react';
import { PageData } from '../../../util/pageGraph';
import { Page, Inner } from '../layout';
import ReactMarkdown from 'react-markdown';
import { H3, H2, H1, Copy } from '../copy';
import Table from './Table';
import remarkGfm from 'remark-gfm'
import { Link } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';

type MarkdownProps = {
  data: string;
  style?: any;
  imageMaxHeight?: number;
}

const Markdown = ({ data, style = {}, imageMaxHeight }: MarkdownProps) => {
  
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props}) => <H1 {...props} style={style}/>,
        h2: ({ node, ...props}) => <H2 {...props} style={style}/>,
        h3: ({ node, ...props}) => <H3 {...props} style={style}/>,
        p: ({ node, ...props}) => <Copy {...props} style={style}/>,
        img: ({ node, ...props }) => <img {...props} style={{
            ...style,
            ...(imageMaxHeight ? { maxHeight: `${imageMaxHeight}px` } : {})
          }} />
        // a: ({ node, ...props}) => {
        //  return props.href
        //   ? <Link to={props.href} {...props}/>
        //   : <span {...props}/>
        // }
      }}
      remarkPlugins={[remarkGfm]}
      //@ts-ignore,
      rehypePlugins={[rehypeRaw]}
      >
      {data}
    </ReactMarkdown>
  );
}

export default Markdown;