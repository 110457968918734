import { FaEnvelope } from "react-icons/fa";
import Button from "./button";

const SubjectContact = ({ subject, includeMore = false }: { subject: string, includeMore?: boolean }) => {
  return (
    <div className="col-span-12 col-start-1 mt-2 mb-2 flex flex-col items-start gap-5 sm:col-start-1 md:flex-row">
      {includeMore && (<Button
        type="link"
        to="/company"
        className="cursor-pointer w-[14rem] bg-white-50 text-black hover:bg-white-100 md:w-fit"
      >
        More About Novitas
      </Button>)}
      <Button
        type="link"
        to="/contact"
        endIcon={<FaEnvelope className="text-white-500" />}
        className="cursor-pointer "
        contactSubjectMessage={subject}
      >
        Contact About {subject}
      </Button>
    </div>
  );
}
export default SubjectContact;